import { Button, Input, notification } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone, LockOutlined, UserOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';

function LoginPage() {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [isVisibleRequest, toggleVisibleRequest] = useState(false);
  const [isVisibleCodeRequest, toggleVisibleCodeRequest] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentProject, setCurrentProject] = useState({});

  useEffect(() => {
    fetch('/aac/projects/current')
      .then((res) => res.json())
      .then((data) => {
        setCurrentProject(data);
      })
      .catch(() => {
        console.info('Error loading current project');
      });

    const authenticateViaCode = (code) => {
      fetch(`${window.location.origin}/aac/auth/login-via/${code}`)
        .then((res) => res.json())
        .then((tokenInfo) => {
          setCookie(tokenInfo);
        })
        .then(() => {
          setTimeout(() => {
            window.location.href = '/';
          }, 100);
        })
        .catch(() => {
          notification.error({
            message: 'Authorization failed',
            description: 'The code is invalid or expired. Try requesting a new one.',
          });
        });
    };

    const setCookie = (tokenInfo) => {
      document.cookie = `jwt=${tokenInfo.accessToken}; expires=${new Date(
        tokenInfo.expiresAt
      ).toUTCString()}; path=/`;
    };

    const loginByCodeIfPresent = () => {
      const code = getQueryCode();
      if (!code) return;

      authenticateViaCode(code);
    };

    const getQueryCode = () => {
      const searchParams = new URLSearchParams(window.location.href.split('?')[1]);
      return searchParams.get('token');
    };

    loginByCodeIfPresent();
  }, []);

  const handleLogin = async () => {
    if (loading || !login || !password || isVisibleCodeRequest) return;
    setLoading(true);
    const res = await fetch('/aac/auth/login', {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: login, password }),
    });
    setLoading(false);
    const status = res.status;
    const body = await res.json();

    if (status === 403) {
      notification.warn({
        message: `Authorization failed`,
        description: body.message,
      });
      toggleVisibleRequest(true);
    } else if (status !== 200 && status !== 201) {
      notification.error({
        message: `Authorization failed`,
        description: body.message,
      });
    } else {
      notification.success({
        message: `You are authorized successfully`,
      });
      setTimeout(() => {
        window.location.href = '/';
      }, 1000);
    }
  };

  const handleRequestAccess = async () => {
    if (loading || !login || !password) return;
    setLoading(true);
    const res = await fetch(`/aac/project/${currentProject.id}/requestaccess`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: login, origin: window.location.origin }),
    });
    setLoading(false);
    const status = res.status;
    const body = await res.json();
    if (status !== 200 && status !== 201) {
      notification.error({
        message: `Request failed`,
        description: body.message,
      });
    } else {
      notification.success({
        message: body.message,
        description: body.description,
      });
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  };

  const handleRequestAccessCode = async () => {
    if (!login) return;
    setLoading(true);

    const res = await fetch(`${window.location.origin}/aac/auth/send-reset-email`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({email: login, origin: window.location.origin || window.location.hostname, 'hostname': window.location.hostname}),
    });

    setLoading(false);
    const status = res.status;

    if (status !== 200) {
      notification.error({
        message: `Request failed`,
        description: 'Failed to send the link',
      });
    } else {
      notification.success({
        message: 'Email successfully sent',
        description: 'Please check your email to continue',
      });
      toggleVisibleCodeRequest(false);
      setLogin('');
    }
  };

  const handleToggleForm = () => {
    toggleVisibleCodeRequest(!isVisibleCodeRequest);
  };

  return (
    <div className="wrapper" style={currentProject && { backgroundColor: currentProject.color }}>
      <div className="logo">
        <img src={`/logo.svg`} alt={''} />
        <br />
        <br />
        <h2>{currentProject?.name}</h2>
      </div>

      <div className="dialog">
        {isVisibleCodeRequest ? (
          <>
            <h2>Forgot Password</h2>
            <h4>Please enter your email address</h4>
            <h4>We will send you verification link</h4>
          </>
        ) : (
          <>
            <h2>Log in to admin dashboard</h2>
          </>
        )}

        <form>
          <Input
            size="large"
            value={login}
            name="login"
            onChange={(e) => setLogin(e.target.value)}
            placeholder={isVisibleCodeRequest ? 'Email address' : 'Your email or user name"'}
            prefix={<UserOutlined />}
          />

          <br />
          <br />

          {!isVisibleCodeRequest ? (
            <>
              <Input.Password
                size="large"
                name="password"
                value={password}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') handleLogin();
                }}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Your corporate password"
                prefix={<LockOutlined />}
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
              <br />
              <br />
            </>
          ) : (
            ''
          )}
        </form>
        <br />

        {!isVisibleRequest && !isVisibleCodeRequest && (
          <Button type="primary" size="large" loading={loading} block onClick={handleLogin}>
            Log in
          </Button>
        )}
        <br />
        <br />

        {isVisibleRequest ? (
          <>
            <Button
              type="primary"
              danger
              size="large"
              loading={loading}
              block
              onClick={handleRequestAccess}
            >
              Request access
            </Button>
            <br />
            <br />
          </>
        ) : (
          ''
        )}

        {isVisibleCodeRequest ? (
          <>
            <Button
              type="primary"
              size="large"
              loading={loading}
              block
              onClick={handleRequestAccessCode}
            >
              Send verification link
            </Button>
            <br />
            <br />
          </>
        ) : (
          ''
        )}

        {!isVisibleCodeRequest ? (
          <>
            <div className="loginInfo">
              To login use your corporate account (MS Active Directory). It's the same password you
              use for access Office resources.
            </div>
            <Button type="link" style={{ marginTop: 10, padding: 0 }} onClick={handleToggleForm}>
              Forgot password?
            </Button>
          </>
        ) : (
          ''
        )}

        {isVisibleCodeRequest && (
          <>
            <Button type="link" style={{ marginTop: 40, padding: 0 }} onClick={handleToggleForm}>
              Back to Login
            </Button>
          </>
        )}
      </div>
    </div>
  );
}

export default LoginPage;
